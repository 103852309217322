export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/index.vue'),
    /* no children */
    meta: {
      "fullWidth": true
    }
  },
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/[...path].vue'),
    /* no children */
  },
  {
    path: '/about',
    name: '/about',
    component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/about.vue'),
    /* no children */
  },
  {
    path: '/accounts',
    /* internal name: '/accounts' */
    /* no component */
    children: [
      {
        path: 'address/:id',
        name: 'address-edit',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/address.[id].vue'),
        /* no children */
        meta: {
          "requiresSignin": true
        }
      },
      {
        path: 'guid/:guid',
        name: '/accounts/guid.[guid]',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/guid.[guid].vue'),
        /* no children */
      },
      {
        path: 'login',
        name: '/accounts/login',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/login.vue'),
        /* no children */
      },
      {
        path: 'old-profile',
        /* internal name: '/accounts/old-profile' */
        /* no component */
        children: [
          {
            path: '',
            name: 'old-accounts-profile',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/old-profile/index.vue'),
            /* no children */
            meta: {
              "requiresSignin": true
            }
          },
          {
            path: 'address/create',
            name: 'accounts-address-create',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/old-profile/address.create.vue'),
            /* no children */
            meta: {
              "requiresSignin": true
            }
          },
          {
            path: 'addresses/:id',
            name: 'accounts-address-edit',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/old-profile/addresses.[id].vue'),
            /* no children */
            meta: {
              "requiresSignin": true
            }
          },
          {
            path: 'edit',
            name: 'accounts-profile-edit',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/old-profile/edit.vue'),
            /* no children */
            meta: {
              "requiresSignin": true
            }
          }
        ],
      },
      {
        path: 'password/reset/key/:uidAndKey',
        name: '/accounts/password.reset.key.[uidAndKey]',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/password.reset.key.[uidAndKey].vue'),
        /* no children */
      },
      {
        path: 'profile',
        name: '/accounts/profile',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile.vue'),
        children: [
          {
            path: '',
            name: 'accounts-profile',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/index.vue'),
            /* no children */
            meta: {
              "requiresSignin": true
            }
          },
          {
            path: 'address',
            /* internal name: '/accounts/profile/address' */
            /* no component */
            children: [
              {
                path: 'edit/:id',
                name: 'accounts-address-edit',
                component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/address/edit.[id].vue'),
                /* no children */
                meta: {
                  "requiresSignin": true
                }
              },
              {
                path: 'new',
                name: 'accounts-address-new',
                component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/address/new.vue'),
                /* no children */
                meta: {
                  "requiresSignin": true
                }
              }
            ],
          },
          {
            path: 'card',
            /* internal name: '/accounts/profile/card' */
            /* no component */
            children: [
              {
                path: 'edit/:id',
                name: 'accounts-card-edit',
                component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/card/edit.[id].vue'),
                /* no children */
                meta: {
                  "requiresSignin": true
                }
              },
              {
                path: 'new',
                name: 'accounts-card-new',
                component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/card/new.vue'),
                /* no children */
                meta: {
                  "requiresSignin": true
                }
              }
            ],
          },
          {
            path: 'cards-and-credits',
            name: 'accounts-cards',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/cards-and-credits.vue'),
            /* no children */
            meta: {
              "requiresSignin": true
            }
          },
          {
            path: 'consultants',
            /* internal name: '/accounts/profile/consultants' */
            /* no component */
            children: [
              {
                path: 'manage',
                name: '/accounts/profile/consultants/manage',
                component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/consultants/manage.vue'),
                /* no children */
              },
              {
                path: 'search',
                name: '/accounts/profile/consultants/search',
                component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/consultants/search.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'dashboard/:repId?',
            name: 'accounts-dashboard',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/dashboard.[[repId]].vue'),
            /* no children */
            meta: {
              "requiresRep": true
            }
          },
          {
            path: 'downline',
            name: 'accounts-downline',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/downline.vue'),
            /* no children */
            meta: {
              "requiresRep": true
            }
          },
          {
            path: 'edit',
            name: 'accounts-edit',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/edit.vue'),
            /* no children */
            meta: {
              "requiresRep": true
            }
          },
          {
            path: 'edit-profile',
            name: '/accounts/profile/edit-profile',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/edit-profile.vue'),
            /* no children */
          },
          {
            path: 'manage/:path?',
            name: 'accounts-manage',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/manage.[[path]].vue'),
            /* no children */
            meta: {
              "requiresRep": true
            }
          },
          {
            path: 'order-detail',
            /* internal name: '/accounts/profile/order-detail' */
            /* no component */
            children: [
              {
                path: ':orderId',
                name: 'accounts-order-detail',
                component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/order-detail/[orderId].vue'),
                /* no children */
                meta: {
                  "requiresSignin": true
                }
              }
            ],
          },
          {
            path: 'order-history',
            name: 'accounts-orders',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/order-history.vue'),
            /* no children */
            meta: {
              "requiresSignin": true
            }
          },
          {
            path: 'parties',
            /* internal name: '/accounts/profile/parties' */
            /* no component */
            children: [
              {
                path: '',
                name: 'accounts-parties',
                component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/parties/index.vue'),
                /* no children */
                meta: {
                  "requiresSignin": true
                }
              },
              {
                path: ':partyId',
                name: 'accounts-party-detail',
                component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/parties/[partyId].vue'),
                /* no children */
                meta: {
                  "requiresSignin": true
                }
              },
              {
                path: 'closed',
                name: 'accounts-closed-parties',
                component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/parties/closed.vue'),
                /* no children */
                meta: {
                  "requiresSignin": true
                }
              }
            ],
          },
          {
            path: 'recent-transactions',
            name: 'accounts-transactions',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/recent-transactions.vue'),
            /* no children */
            meta: {
              "requiresRep": true
            }
          },
          {
            path: 'return-detail',
            /* internal name: '/accounts/profile/return-detail' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'accounts-return-detail',
                component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/return-detail/[id].vue'),
                /* no children */
                meta: {
                  "requiresSignin": true
                }
              }
            ],
          },
          {
            path: 'returns',
            name: 'accounts-returns',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/returns.vue'),
            /* no children */
            meta: {
              "requiresSignin": true
            }
          },
          {
            path: 'start-return',
            name: '/accounts/profile/start-return',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/start-return.vue'),
            /* no children */
          },
          {
            path: 'sync',
            name: 'accounts-sync',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/sync.vue'),
            /* no children */
            meta: {
              "requiresRep": true
            }
          },
          {
            path: 'tickets',
            name: 'accounts-tickets',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/profile/tickets.vue'),
            /* no children */
            meta: {
              "requiresSignin": true
            }
          }
        ],
      },
      {
        path: 'signin',
        name: 'accounts-signin',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/signin.vue'),
        /* no children */
      },
      {
        path: 'signup',
        name: 'accounts-signup',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/accounts/signup.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/alerts',
    name: '/alerts',
    component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/alerts.vue'),
    /* no children */
  },
  {
    path: '/faqs',
    name: '/faqs',
    component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/faqs.vue'),
    /* no children */
  },
  {
    path: '/fashion-fix',
    /* internal name: '/fashion-fix' */
    /* no component */
    children: [
      {
        path: '',
        name: 'fashion-fix',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/fashion-fix/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/fashion-fix/[id]',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/fashion-fix/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/find-consultant',
    name: 'find-consultant',
    component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/find-consultant.vue'),
    /* no children */
  },
  {
    path: '/founders',
    name: 'founders',
    component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/founders.vue'),
    /* no children */
    meta: {
      "fullWidth": true
    }
  },
  {
    path: '/join/:consultantId?',
    name: '/join.[[consultantId]]',
    component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/join.[[consultantId]].vue'),
    /* no children */
  },
  {
    path: '/lop',
    /* internal name: '/lop' */
    /* no component */
    children: [
      {
        path: '',
        name: 'lop',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/lop/index.vue'),
        /* no children */
      },
      {
        path: ':access',
        name: 'lop-level',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/lop/[access].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/my/:consultantId',
    name: 'consultant',
    component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/my.[consultantId].vue'),
    /* no children */
  },
  {
    path: '/my/:consultantId/parties',
    name: 'consultant-parties',
    component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/my.[consultantId].parties.vue'),
    /* no children */
  },
  {
    path: '/opportunities',
    /* internal name: '/opportunities' */
    /* no component */
    children: [
      {
        path: '',
        name: 'opportunities',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/opportunities/index.vue'),
        /* no children */
      },
      {
        path: ':joinId/success',
        name: 'opportunities-success',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/opportunities/[joinId].success.vue'),
        /* no children */
      },
      {
        path: 'signup',
        name: 'opportunities-signup',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/opportunities/signup.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/promotions',
    /* internal name: '/promotions' */
    /* no component */
    children: [
      {
        path: '',
        name: 'promotions',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/promotions/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/pulse',
    /* internal name: '/pulse' */
    /* no component */
    children: [
      {
        path: '',
        name: 'pulse',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/pulse/index.vue'),
        /* no children */
      },
      {
        path: ':slug',
        name: 'blog-article',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/pulse/[slug].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/shop',
    /* internal name: '/shop' */
    /* no component */
    children: [
      {
        path: ':path(.*)?',
        name: 'shop',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/shop/[[...path]].vue'),
        /* no children */
      },
      {
        path: 'cart',
        name: 'shop-cart',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/shop/cart.vue'),
        /* no children */
      },
      {
        path: 'checkout',
        /* internal name: '/shop/checkout' */
        /* no component */
        children: [
          {
            path: '',
            name: 'shop-checkout',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/shop/checkout/index.vue'),
            /* no children */
          },
          {
            path: ':orderId/success',
            name: 'shop-checkout-success',
            component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/shop/checkout/[orderId].success.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'products/:slug/:consultantId?',
        name: 'product-detail',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/shop/products.[slug].[[consultantId]].vue'),
        /* no children */
        meta: {
          "fullWidth": true
        }
      },
      {
        path: 'quickadd',
        name: 'shop-quickadd',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/shop/quickadd.vue'),
        /* no children */
      },
      {
        path: 'wishlist',
        name: 'shop-wishlist',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/shop/wishlist.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/zi',
    /* internal name: '/zi' */
    /* no component */
    children: [
      {
        path: '',
        name: 'zi',
        component: () => import('/var/www/shop.paparazziaccessories.com/node-apps/site/src/pages/zi/index.vue'),
        /* no children */
        meta: {
          "fullWidth": true
        }
      }
    ],
  }
]
